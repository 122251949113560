import React, { useState } from 'react';
import Header from '../../components/company/dashboard/Header/Header';
import Sidebar from '../../components/company/dashboard/Sidebar/Sidebar';
import timeImg from '../../assets/imgs/time.png';
import './FavoriteCandidates.css';
import ScheduledInterviewUpcomingList from '../../components/company/dashboard/Scheduled Interview/ScheduledInterviewUpcomingList';
import ScheduledInterviewPastList from '../../components/company/dashboard/Scheduled Interview/ScheduledInterviewPastlist';
import './ScheduledCandidate.css'


const ScheduledCandidates = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [showUpcoming, setShowUpcoming] = useState(true); // State to toggle between upcoming and past lists

  const handleToggleSidebar = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  return (
    <div className={`main-section favsection`}>
      <Header />
      <div className='row box'>
        <div className={`col-lg-2 col-md-2 col-sm-4 col-4 ${isSidebarCollapsed ? 'sidebarCollapsed' : ''}`}>
          <Sidebar onToggleSidebar={handleToggleSidebar} />
        </div>
        <div className={`col-lg-10 col-md-10 col-sm-8 col-8 contentbar ${isSidebarCollapsed ? 'contentExpanded' : ''}`}>
          <div className='container-fluid'>
            <h2 className='main-heading'><img src={timeImg} alt='favicon' />Scheduled Candidates</h2>
            
            {/* Toggle Buttons */}
            <div className='toggle-buttons'>
              <button className={showUpcoming ? 'active' : ''} onClick={() => setShowUpcoming(true)}>Upcoming Event</button>
              <button className={!showUpcoming ? 'active' : ''} onClick={() => setShowUpcoming(false)}>Past Event</button>
            </div>
            
            {/* Conditionally render components based on the selected button */}
            {showUpcoming ? <ScheduledInterviewUpcomingList /> : <ScheduledInterviewPastList/>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduledCandidates;
