import React from 'react';
import gridBtn from '../../../assets/imgs/gridBtn.png';
import listBtn from '../../../assets/imgs/listBtn.png';
import './ToggleViewButton.css'
const ToggleViewButton = ({ view, setView }) => {
  return (
    <div className='grid-list-btns'>
        <img onClick={() => setView('grid')} src={gridBtn} alt="Grid View" />
         <img onClick={() => setView('list')} src={listBtn} alt="List View" />
     
    </div>
  );
};

export default ToggleViewButton;
