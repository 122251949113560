import React from 'react'
import './ScheduledInterviewBox.css';
import rightArrowIcon from '../../../../assets/imgs/righArrow.png';
function ScheduledInterviewBox() {
  return (
    <div className='main-count-box'>
    <h4>Scheduled Interview</h4>
    <h1 className='counts'>02
      <img src={rightArrowIcon} alt='right-arrow-btn'/>
    </h1>
    </div>
  )
}

export default ScheduledInterviewBox