import React from 'react';
import userImg from '../../../../assets/imgs/user.png';
import './ScheduledInterviewPastlist.css';

function ScheduledInterviewPastlist() {
  const pastInterviews = [
    {
      date: 'Yesterday',
      time: '09:00 - 09:30AM EST',
      candidateId: 'ID32347',
      userImg: null,
    },
    {
      date: 'Last Week',
      time: '02:00 - 02:30PM EST',
      candidateId: 'ID32348',
      userImg: null,
    },
    // Add more past interview objects as needed
  ];

  return (
    <div className='schedule-box'>
      {pastInterviews.map((interview, index) => (
        <div className='scheduledList' key={index}>
          <div className='scheduled-date'>
            <h6>{interview.date}</h6>
            <p>{interview.time}</p>
          </div>
          <div className='candidate-info'>
            <div className='sub-box'>
              <img src={interview.userImg || userImg} alt='userImg' />
              <h6>{interview.candidateId}</h6>
            </div>
          </div>
          <div className='zoom-btn'>
            <button className='sentofferbtn'>Send Offer</button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ScheduledInterviewPastlist;
