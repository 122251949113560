import React, { useContext, useEffect } from 'react';

import Header from '../../components/company/dashboard/Header/Header';
import Sidebar from '../../components/company/dashboard/Sidebar/Sidebar';
import ProfileBox from '../../components/company/dashboard/Profile/ProfileBox';
import './Profile.css';
import { useNavigate } from 'react-router-dom';
import ProfileAbout from '../../components/company/dashboard/Profile/ProfileAbout';
import CompanyImageSlider from '../../components/company/dashboard/Profile/CompanyImageSlider';
import CompapnyVideoIntro from '../../components/company/dashboard/Profile/CompapnyVideoIntro';
import CompanyReview from '../../components/company/dashboard/Profile/CompanyReview';
import Coverphoto from '../../assets/imgs/coverphoto.png'
import './CompanyPublic.css';


const CompanyPublic = () => {
   
    const navigate = useNavigate();



    return (
        <div className="dashboard profile">
            <Header />
            <div className='coverphoto-sec'>
                           <img src={Coverphoto} alt='coverphoto'/>
                        </div>
            <div className='container dashboardrow' style={{ minHeight: '100vh' }}>
        
                    <main>
                     
                        <ProfileBox/>
                        <ProfileAbout/>
                        <CompanyImageSlider/>
                        <CompapnyVideoIntro/>
                        <CompanyReview/>
                      
                    </main>
              
            </div>
        </div>
    );
};

export default CompanyPublic;
