// Sidebar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import profileImage from '../../../../assets/imgs/Rectangle.png';
import badgeIcon from '../../../../assets/imgs/BadgeIcon.png';
import dashboardIcon from '../../../../assets/imgs/DashboardIcon.png';
import searchIcon from '../../../../assets/imgs/Search.png';
import hotIcon from '../../../../assets/imgs/Fire_fill.png';
import resourcesIcon from '../../../../assets/imgs/Book_open_fill.png';
import matchIcon from '../../../../assets/imgs/MatchedIcon.png';
import scheduleIcon from '../../../../assets/imgs/Time_fill.png';
import sentIcon from '../../../../assets/imgs/SentOfferIcon.png';
import favoriteIcon from '../../../../assets/imgs/HeartIcon.png';
import collapsedIcon from '../../../../assets/imgs/collapse.png';
import upgradeIcon from '../../../../assets/imgs/upgrade.png';

import '../Sidebar/Sidebar.css';

const Sidebar = ({ onToggleSidebar }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
        onToggleSidebar(!isCollapsed); // Pass the updated state to Dashboard.js
    };

    return (
        <aside className={isCollapsed ? 'collapsed' : ''}>
            <div className='profile-box'>
                <Link to="/company/dashboard">
                    <div className='profile-box-wrap'>
                        <div className='img-box'>
                            <img src={profileImage} alt="company-logo" className='company-logo-img' />
                        </div>
                        {!isCollapsed && (
                            <div className='company-name'>
                                <h6>Company Name</h6>
                                <img src={badgeIcon} alt='icon-badge' className='badgeIcon' />
                            </div>
                        )}
                    </div>
                </Link>
            </div>
            <ul>
                <li className='active'>
                    <Link to="/company/dashboard">
                        <img src={dashboardIcon} className='sidebar-icons' alt='dashbordicon' />
                        {!isCollapsed && 'Dashboard'}
                    </Link>
                </li>
                <li>
                    <Link to="/company/search-candidate">
                        <img src={searchIcon} className='sidebar-icons' alt='searchicon' />
                        {!isCollapsed && 'Search Candidates'}
                    </Link>
                </li>
                <li>
                    <Link to="/company/hot-candidates">
                        <img src={hotIcon} className='sidebar-icons' alt='hoticon' />
                        {!isCollapsed && 'Hot Candidates'}
                    </Link>
                </li>
                <li>
                    <Link to="/company/">
                        <img src={resourcesIcon} className='sidebar-icons' alt='resourseicon' />
                        {!isCollapsed && 'Resources'}
                    </Link>
                </li>

                {!isCollapsed && <h6>My Candidate</h6>}
                <li>
                    <Link to="/company/favorite-candidates">
                        <img src={favoriteIcon} className='sidebar-icons' alt='favoriteicon' />
                        {!isCollapsed && 'Favorite Candidates'}
                    </Link>
                </li>
                <li>
                    <Link to="/company/match-candidates">
                        <img src={matchIcon} className='sidebar-icons' alt='matchicon' />
                        {!isCollapsed && 'Matched Candidates'}
                    </Link>
                </li>
                <li>
                    <Link to="/company/scheduled-candidates">
                        <img src={scheduleIcon} className='sidebar-icons' alt='scheduleicon' />
                        {!isCollapsed && 'Scheduled Candidates'}
                    </Link>
                </li>
                <li>
                    <Link to="/company/dashboard">
                        <img src={sentIcon} className='sidebar-icons' alt='senticon' />
                        {!isCollapsed && 'Sent Offer'}
                    </Link>
                </li>
            </ul>
            <div className='toggle-btn' >
             <div className='upgrade-btn'>
             <Link to="/upgrade"> <img src={upgradeIcon} className='sidebar-icons' alt='senticon' />
               {!isCollapsed && 'Upgrade Account'}
               
               </Link>
             </div>
                    <div onClick={toggleSidebar} className='collapse-btn'>
                    {isCollapsed 
        ? <img src={collapsedIcon} alt='expand icon' />
        :    <><img src={collapsedIcon} className='sidebar-icons' alt='collapse icon' /> Collapse Menu </>
     
    }
                    </div>
</div>
        </aside>
    );
};

export default Sidebar;
