import React, { useState, useEffect } from 'react';
import Header from '../../components/company/dashboard/Header/Header';
import BannerSection from '../../components/company/browseCandidates/Banner';
import { getCandidates } from '../../services/candidateService';
import CandidateGrid from '../../components/company/browseCandidates/CandidateGrid';
import CandidateList from '../../components/company/browseCandidates/CandidateList';
import ToggleViewButton from '../../components/company/browseCandidates/ToggleViewButton';
import './BrowseCandidate.css'
const BrowseCandidate = () => {
  const [candidates, setCandidates] = useState([]);
  const [view, setView] = useState('grid');

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const data = await getCandidates();
        console.log('Fetched candidates:', data); // Debugging line
        setCandidates(data);
      } catch (error) {
        console.error('Failed to fetch candidates:', error); // Error logging
      }
    };
    fetchCandidates();
  }, []);

  return (
    <div className='main-section'>
      <Header />
      <BannerSection />
      <div className='container browsesec'>
        <ToggleViewButton view={view} setView={setView} />
        {candidates.length === 0 ? (
          <p>No candidates found</p>
        ) : (
          view === 'grid' ? (
            <CandidateGrid candidates={candidates} />
          ) : (
            <CandidateList candidates={candidates} />
          )
        )}
      </div>
    </div>
  );
};

export default BrowseCandidate;
