import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Header from '../../components/company/dashboard/Header/Header';
import Sidebar from '../../components/company/dashboard/Sidebar/Sidebar';
import ProfileBox from '../../components/company/dashboard/Profile/ProfileBox';
import './Dashboard.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import FavoriteCandidateBox from '../../components/company/dashboard/Favorite Candidates/FavoriteCandidateBox';
import MatchCandidateBox from '../../components/company/dashboard/Match Candidates/MatchCandidateBox';
import ScheduledInterviewBox from '../../components/company/dashboard/Scheduled Interview/ScheduledInterviewBox';
import SentOfferBox from '../../components/company/dashboard/Sent Offer/SentOfferBox';
import AccountProgress from '../../components/company/dashboard/Account/AccountProgress';

const Dashboard = () => {
    const { isAuthenticated, user } = useContext(AuthContext);
    const navigate = useNavigate();
    const [companyData, setCompanyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    const handleToggleSidebar = (isCollapsed) => {
        setIsSidebarCollapsed(isCollapsed);
    };

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/company/login');
        } else {
            fetchCompanyData();
        }
    }, [isAuthenticated, navigate]);

    const fetchCompanyData = async () => {
        try {
            const response = await axios.get(`/api/companies/user/${user._id}`);
            console.log("API Response:", response.data);
            setCompanyData(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching company data:", error);
            setLoading(false);
        }
    };

    if (!isAuthenticated) {
        return null;
    }

    if (loading) return <p>Loading company data...</p>;

    return (
        <div className="dashboard">
            <Header />
            <div className='row dashboardrow' style={{ minHeight: '100vh' }}>
                <div className={`col-lg-2 col-md-2 col-sm-4 col-4 sidebar ${isSidebarCollapsed ? 'sidebarCollapsed' : ''}`}>
                    <Sidebar onToggleSidebar={handleToggleSidebar} />
                </div>
                <div className={`col-lg-10 col-md-10 col-sm-8 col-8 sidebar-column  contentbar ${isSidebarCollapsed ? 'contentExpanded' : ''}`}>
                    <main>
                        <div className='welcome-text'>
                            <h6>Welcome back to your dashboard, <span>{companyData ? companyData.name : 'Company'}</span></h6>
                        </div>
                        <ProfileBox companyData={companyData} />
                        <AccountProgress />
                        <div className='row'>
                            <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                                <FavoriteCandidateBox />
                            </div>
                            <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                                <MatchCandidateBox />
                            </div>
                            <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                                <ScheduledInterviewBox />
                            </div>
                            <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                                <SentOfferBox />
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
