import React from 'react'
import heroImg from '../../../assets/imgs/Hero.png'
import './Banner.css'
function Banner() {
  return (
    <div className='banner-sec'>
        <img src={heroImg} alt='browse-hero-img'/>
        <div className='banner-content'>
            <h2>Browse Thousands of Highly Skilled <br/>
            Military Talent Eager to Join Your Company</h2>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.
            </p>
        </div>
    </div>
  )
}

export default Banner