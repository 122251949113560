import React from 'react';
import './CompanyReview.css';
import starImg from '../../../../assets/imgs/star.png'; // Importing star image

const reviews = [
  {
    rating: 5,
    description: "Working at Cybersurance has been an incredible experience. The company truly values the skills and discipline that veterans bring to the table. I've had the opportunity to grow professionally while contributing to meaningful cybersecurity projects that make a real difference.",
    clientName: 'John Doe',
    industry: 'Software Development',
  },
  {
    rating: 4,
    description: "Working at Cybersurance has been an incredible experience. The company truly values the skills and discipline that veterans bring to the table. I've had the opportunity to grow professionally while contributing to meaningful cybersecurity projects that make a real difference.",
    clientName: 'Jane Smith',
    industry: 'Marketing',
  },
  {
    rating: 5,
    description: "Working at Cybersurance has been an incredible experience. The company truly values the skills and discipline that veterans bring to the table. I've had the opportunity to grow professionally while contributing to meaningful cybersecurity projects that make a real difference.",
    clientName: 'Mike Johnson',
    industry: 'Consulting',
  },
  {
    rating: 3,
    description: "Working at Cybersurance has been an incredible experience. The company truly values the skills and discipline that veterans bring to the table. I've had the opportunity to grow professionally while contributing to meaningful cybersecurity projects that make a real difference.",
    clientName: 'Emily Davis',
    industry: 'E-commerce',
  },
];

function CompanyReview() {
  return (
    <div className='company-reviews'>
      <h4>Client Reviews</h4>
      <div className='review-container'>
        {reviews.map((review, index) => (
          <div className='review-box' key={index}>
            <div className='rating'>
              {/* Displaying stars based on rating */}
              {Array.from({ length: review.rating }).map((_, starIndex) => (
                <img key={starIndex} src={starImg} alt="star" className='star-img' />
              ))}
            </div>
            <p className='description'>{review.description}</p>
            <p className='client-name'>{review.clientName}</p>
            <p className='industry-type'>{review.industry}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CompanyReview;
