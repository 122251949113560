import React, { useState, useEffect, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './CandidateGrid.css';
import { FaRegHeart} from 'react-icons/fa';
import CandidateSearch from './CandidateSearch';
import rightArrow from '../../../assets/imgs/right-arrow.png';
import bottomtArrow from '../../../assets/imgs/bottomIcon.png';


const CandidateGrid = ({ candidates }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredCandidates, setFilteredCandidates] = useState(candidates);
  const [searchTerm, setSearchTerm] = useState('');
  const candidatesPerPage = 3; // Number of candidates to display per page

  const indexOfLastCandidate = currentPage * candidatesPerPage;
  const indexOfFirstCandidate = indexOfLastCandidate - candidatesPerPage;
  const currentCandidates = filteredCandidates.slice(indexOfFirstCandidate, indexOfLastCandidate);
  const totalPages = Math.ceil(filteredCandidates.length / candidatesPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearch = useCallback((searchTerm) => {
    setSearchTerm(searchTerm);
    const searchedCandidates = candidates.filter(candidate => {
      const id = candidate.recruiterFlowId ? candidate.recruiterFlowId.toLowerCase() : '';
      const branch = candidate.branch ? candidate.branch.toLowerCase() : '';
      return id.includes(searchTerm.toLowerCase()) || branch.includes(searchTerm.toLowerCase());
    });
    setFilteredCandidates(searchedCandidates);
  }, [candidates]);

  const handleFilter = (filters) => {
    let filteredList = candidates;

    if (filters.branch) {
      filteredList = filteredList.filter(candidate => 
        candidate.branch && candidate.branch.toLowerCase().includes(filters.branch.toLowerCase())
      );
    }
    if (filters.clearance) {
      filteredList = filteredList.filter(candidate => 
        candidate.clearance && candidate.clearance.toLowerCase().includes(filters.clearance.toLowerCase())
      );
    }
    if (filters.rank) {
      filteredList = filteredList.filter(candidate => 
        candidate.rank && candidate.rank.toLowerCase().includes(filters.rank.toLowerCase())
      );
    }

    setFilteredCandidates(filteredList);
    setCurrentPage(1);
  };

  const handleSort = (sortType) => {
    let sortedList = [...filteredCandidates];
    sortedList.sort((a, b) => {
      const aValue = a[sortType] ? a[sortType].toLowerCase() : '';
      const bValue = b[sortType] ? b[sortType].toLowerCase() : '';
      return aValue.localeCompare(bValue);
    });
    setFilteredCandidates(sortedList);
  };

  // Create an array of page numbers for pagination
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  useEffect(() => {
    setCurrentPage(1); // Reset page to 1 when candidates change
  }, [filteredCandidates, handleSearch]);

  return (
    <>
      <CandidateSearch onSearch={handleSearch} onFilter={handleFilter} onSort={handleSort} />
      <div className="row">
        {currentCandidates.length > 0 ? (
          currentCandidates.map(candidate => (
            <div key={candidate._id} className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="candidateGridBox">
                <iframe 
                  src='https://www.youtube.com/embed/6_LmbJsxlb0'  
                  width="100%"  
                  height="150px" 
                  title="Candidate Video" 
                  allowFullScreen 
                ></iframe>
                <div className='candidate-header-info'>
                  <div className='left'>
                    <h3 className='candidate-id'>{candidate.recruiterFlowId} <span>Veteran</span></h3>
                    <h6 className='candidate-branch'>{candidate.branch}</h6>
                  </div>
                  <div className='right-fav-btn'>
                    <FaRegHeart className="heart-icon" style={{ color: 'gray', fontSize: '20px' }} />
                  </div>
                </div>

                <div className='candidate-detail'>
                  <div className='fields'>
                    <div className='field'><p className='field-name'>Preferred Job:</p> <p className='field-value'>Cyber Security Analyst</p></div>
                    <div className='field'><p className='field-name'>Clearance:</p> <p className='field-value'>Top Secret with no Poly</p></div>
                    <div className='field'><p className='field-name'>Military Rank:</p> <p className='field-value'>E4</p></div>
                    <div className='field'><p className='field-name'>Certifications:</p> <p className='field-value'>CompTIA Security+, Network+, A+, CASP + (Anticipate Completion Date – August 2024)</p></div>
                    <div className='field'><p className='field-name'>Education:</p> <p className='field-value'>Information Technology Southern New Hampshire University (2025)</p></div>
                    <div className='field'><p className='field-name'>Available:</p> <p className='field-value'>August 25, 2025</p></div>
                  </div>
                </div>

                <div className='profile-btns'>
                  <button className='view-more-btn'>More Options <img src={bottomtArrow} alt='bottomIcon' /></button>
                  <button className='view-profile-btn'>View Profile <img src={rightArrow} alt='right' /></button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>No candidates found.</div>
        )}
      </div>

      {/* Pagination */}
      <div className="pagination">
        <button className='page-link-previous' onClick={handlePrevious} disabled={currentPage === 1}>  &lt;</button>
        {pageNumbers.map(number => (
          <button
            key={number}
            className={`page-button ${currentPage === number ? 'active' : ''}`}
            onClick={() => paginate(number)}
          >
            {number}
          </button>
        ))}
        <button className='page-link-next' onClick={handleNext} disabled={currentPage === totalPages}>   &gt;</button>
      </div>
    </>
  );
};

export default CandidateGrid;
