import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Header from '../../components/company/dashboard/Header/Header';
import Sidebar from '../../components/company/dashboard/Sidebar/Sidebar';
import ProfileBox from '../../components/company/dashboard/Profile/ProfileBox';
import './Profile.css';
import { useNavigate } from 'react-router-dom';
import ProfileAbout from '../../components/company/dashboard/Profile/ProfileAbout';
import CompanyImageSlider from '../../components/company/dashboard/Profile/CompanyImageSlider';
import CompapnyVideoIntro from '../../components/company/dashboard/Profile/CompapnyVideoIntro';
import CompanyReview from '../../components/company/dashboard/Profile/CompanyReview';
import CompanyCoverphoto from '../../components/company/dashboard/Profile/CompanyCoverphoto';



const Profile = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/company/login');
        }
    }, [isAuthenticated, navigate]);

    if (!isAuthenticated) {
        return null; // Prevent rendering anything if not authenticated
    }

    return (
        <div className="dashboard profile">
            <Header />
            <div className='row dashboardrow' style={{ minHeight: '100vh' }}>
                <div className='col-lg-2 col-md-2 col-sm-4 col-4'>
                    <Sidebar />
                </div>
                <div className='col-lg-10 col-md-10 col-sm-8 col-8 sidebar-column'>
                    <main>
                        <div className='welcome-text'>
                            <h6>Welcome back to your Profile, <span>Company Name</span></h6>
                        </div>
                        <ProfileBox/>
                        <ProfileAbout/>
                        <CompanyImageSlider/>
                        <CompapnyVideoIntro/>
                        <CompanyReview/>
                        <CompanyCoverphoto/>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default Profile;
