import React, { createContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// AuthContext create karna
export const AuthContext = createContext();

// AuthProvider component jo ke children ko wrap karega aur authentication data provide karega
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null); // Initially null
    const [user, setUser] = useState(null); // State for storing user information
    const navigate = useNavigate();
    const location = useLocation(); // Get the current route

    // Check for token when the app loads
    useEffect(() => {
        const token = sessionStorage.getItem('token');
        console.log('Token:', token); // Debugging line

        if (token) {
            setIsAuthenticated(true);
            // Optionally, fetch user information here if needed
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    // Redirect after authentication state is determined
    useEffect(() => {
        // Don't redirect if the user is on the register or login page
        if (isAuthenticated === false && location.pathname !== '/company/register' && location.pathname !== '/company/login') {
            navigate('/company/login');
        }
    }, [isAuthenticated, navigate, location]);

    // Login function
    const login = (userToken, userData) => {
        setIsAuthenticated(true);
        setUser(userData); // Store user data
        sessionStorage.setItem('token', userToken); // Store the actual token
        navigate('/company/dashboard');
    };

    // Logout function
    const logout = () => {
        setIsAuthenticated(false);
        setUser(null); // Clear user data on logout
        sessionStorage.removeItem('token');
        navigate('/company/login');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
            {isAuthenticated !== null && children}
        </AuthContext.Provider>
    );
};
