import React, { useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import './CandidateSearch.css'; // You can style the component here

const CandidateSearch = ({ onSearch, onFilter, onSort }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterVisible, setFilterVisible] = useState(false);
  const [filters, setFilters] = useState({
    branch: '',
    clearance: '',
    rank: ''
  });

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle search button click
  const handleSearchClick = () => {
    onSearch(searchTerm); // Pass the search term to the parent component
  };

  // Handle filter icon click to toggle filter options
  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
  };

  // Handle filter change
  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    });
  };

  // Apply filter
  const applyFilter = () => {
    onFilter(filters);
  };

  // Remove filter
  const clearFilters = () => {
    setFilters({
      branch: '',
      clearance: '',
      rank: ''
    });
    onFilter({}); // Send empty filters to clear
  };

  // Handle sort change
  const handleSortChange = (e) => {
    onSort(e.target.value);
  };

  return (
    <div className="candidate-search-container">
      <input 
        type="text" 
        placeholder="Search candidate..." 
        value={searchTerm} 
        onChange={handleSearchChange} 
        className="search-input"
      />
      <div className='filerIcon' onClick={toggleFilterVisibility}>
      <FaFilter className="filter-icon" />
      </div>
      

      {filterVisible && (
        <div className="filters-container">
          <div className="filter-field">
            <label>Branch:</label>
            <input 
              type="text" 
              name="branch" 
              value={filters.branch} 
              onChange={handleFilterChange} 
            />
          </div>
          <div className="filter-field">
            <label>Clearance:</label>
            <input 
              type="text" 
              name="clearance" 
              value={filters.clearance} 
              onChange={handleFilterChange} 
            />
          </div>
          <div className="filter-field">
            <label>Rank:</label>
            <input 
              type="text" 
              name="rank" 
              value={filters.rank} 
              onChange={handleFilterChange} 
            />
          </div>
          <button onClick={applyFilter} className="apply-filter-button">Apply Filter</button>
          <button onClick={clearFilters} className="clear-filter-button">Clear Filter</button>
        </div>
      )}
<button onClick={handleSearchClick} className="search-button">Search Candidate</button>
      <select onChange={handleSortChange} className="sort-dropdown">
        <option value="">Sort By</option>
        <option value="branch">Branch</option>
        <option value="clearance">Clearance</option>
        <option value="rank">Rank</option>
      </select>
    </div>
  );
};

export default CandidateSearch;
