// src/ImageCarousel.js
import React from 'react';
import Slider from 'react-slick';
import './CompanyImageSlider.css';

const CompanyImageSlider = () => {
  const images = [
    'https://via.placeholder.com/300x200?text=Image+1',
    'https://via.placeholder.com/300x200?text=Image+2',
    'https://via.placeholder.com/300x200?text=Image+3',
    'https://via.placeholder.com/300x200?text=Image+4',
    'https://via.placeholder.com/300x200?text=Image+5',
    'https://via.placeholder.com/300x200?text=Image+6',
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // 3 images at a time
    slidesToScroll: 1,
    autoplay: true, // Autoplay ko enable karne ke liye
    autoplaySpeed: 2000,
  };

  return (
    <div className="carousel-container">
        <h4>Company Gallery</h4>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="carousel-item">
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CompanyImageSlider;
