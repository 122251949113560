import React from 'react'
import coverphoto from '../../../../assets/imgs/coverphoto.png';
import './CompanyCoverphoto.css';
function CompanyCoverphoto() {
  return (
    <div className='company-coverphoto'>
        <h4>Cover Photo</h4>
        <img src={coverphoto} alt='coverphoto'/>
        </div>
  )
}

export default CompanyCoverphoto