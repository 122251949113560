import React, { useState } from 'react';
import axios from 'axios';
import './Register.css';
import { Link, useNavigate } from 'react-router-dom'; // Use useNavigate
import firstProgressbar from '../../../assets/imgs/progress1.png';
import secondProgressbar from '../../../assets/imgs/progress2.png';
import userImg from '../../../assets/imgs/userblank.png';
import partnerIcon from '../../../assets/imgs/partnerIcon.png';

import bgImg from '../../../assets/imgs/bg.png';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../../components/company/dashboard/Header/Header';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 

const Register = () => {
    const [step, setStep] = useState(1);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formData, setFormData] = useState({
        companyname: '',
        name: '',
        title: '',
        email: '',
        phone: '',
        username: '',
        password: '',
        confirmPassword: '',
        userType: 'Company',
    });
    const [isCompany, setIsCompany] = useState(true);
    const [isUser, setIsUser] = useState(false); // State for user checkbox
    const [showForm, setShowForm] = useState(false); // State to control form visibility
    const navigate = useNavigate(); // Use useNavigate for redirection

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleUserTypeChange = (type) => {
        if (type === 'company') {
            setIsCompany(true);
            setIsUser(false);
        } else {
            setIsUser(true);
            setIsCompany(false);
        }
    };

    const handleShowForm = () => {
        setShowForm(true);
        setStep(1); // Reset step when showing form
    };

    const nextStep = () => {
        if (step === 1) {
            if (!formData.email || !formData.phone || !formData.companyname || !formData.title, !formData.name, !formData.userType) {
                toast.error('Please fill out all required fields.');
                return;
            }
        } else if (step === 2) {
            if (formData.password !== formData.confirmPassword) {
                toast.error('Passwords do not match.');
                return;
            }
        }
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            toast.error('Passwords do not match.');
            return;
        }
        try {
            const response = await axios.post('http://localhost:5000/api/users/register', formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response.data.message);
            setFormData({
                companyname: '',
                name: '',
                title: '',
                email: '',
                phone: '',
                username: '',
                password: '',
                confirmPassword: '',
                userType: 'Company'

            });
            setStep(1);
            setShowForm(false); // Hide form after registration
        } catch (error) {
            toast.error(error.response ? error.response.data.message : 'Registration failed');
        }
    };

    const handleJoinUser = () => {
        navigate('/talent/register'); // Redirect to user registration page
    };

    return (
        <>
            <Header />
            <ToastContainer />
            <div className="register-container">
                <div className='row register-row'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='register-banner'>
                            <img src={bgImg} alt='company-banner' />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='form main-box'>
                            <>
                            {!showForm && (
                            <h2 className="user-type-heading">
                               Join as a Partner <br/>
                               or Military Talent
                            </h2>
                               )}
                            </>
                            {/* Show user type selection */}
                            {!showForm && (
                           <div className="user-type-selection">
                           <div className={`selection-box ${isCompany ? 'active-company' : ''}`}>
                               <label>
                                   <img src={partnerIcon} alt='partnericon' />
                                   <input 
                                       type="radio" 
                                       checked={isCompany} 
                                       onChange={() => handleUserTypeChange('company')} 
                                   />
                               </label>
                               <p>I’m a partner company looking for top military talent</p>
                           </div>
                       
                           <div className={`selection-box ${isUser ? 'active-user' : ''}`}>
                               <label>
                                   <img src={userImg} alt='userImg' />
                                   <input 
                                       type="radio" 
                                       checked={isUser} 
                                       onChange={() => handleUserTypeChange('user')} 
                                   />
                               </label>
                               <p>I’m a military talent looking for great companies</p>
                           </div>
                       </div>
                       
                            )}

                            {/* Show button for company registration */}
                            {isCompany && !showForm && (
                                <div className='button-container'>
                                    <button className='company-register-btn' onClick={handleShowForm}>
                                       Join as Partner Company
                                    </button>

                                    <p className="login-link selection-login">
                                        Already have an account? <Link to="/company/login">Login</Link>
                                    </p>
                                </div>
                            )}

                            {isUser && !showForm && (
                                <div className='button-container'>
                                    <button className='user-register-btn' onClick={handleJoinUser}>
                                        Join as Talent
                                    </button>

                                    <p className="login-link  selection-login">
                                        Already have an account? <Link to="/company/login">Login</Link>
                                    </p>
                                </div>
                            )}

                            {showForm && isCompany && (
                                <form onSubmit={handleRegister}>
                                    <h4>Sign Up To Hire Military Talent</h4>
                                    <p className="login-link">
                                        Already have an account? <Link to="/company/login">Login</Link>
                                    </p>
                                    <div className="company-fields">
                                        {step === 1 && (
                                            <div className="1st-step-fields">
                                                <img src={firstProgressbar} alt="progressbar1" className='progressbar' />

                                                <h6>Let’s Get To Know You</h6>
                                                <p className='sub-para'>Start with your Company name and Main Point of Contact.</p>
                                                <label>Company Name <span>*</span></label>
                                                <input 
                                                    type="text" 
                                                    name="companyname" 
                                                    placeholder="Type here" 
                                                    value={formData.companyname} 
                                                    onChange={handleChange} 
                                                    required 
                                                />
                                                <label>Point of Contact <span>*</span></label>
                                                <div className="row-combind">
                                                    <div className='name-wrap field-combind'>
                                                        <input 
                                                            type="text" 
                                                            name="name" 
                                                            placeholder="Type here" 
                                                            value={formData.name} 
                                                            onChange={handleChange} 
                                                            required 
                                                        />
                                                        <h6>Name</h6>
                                                    </div>
                                                    <div className='title-wrap field-combind'>
                                                        <input 
                                                            type="text" 
                                                            name="title" 
                                                            placeholder="Type here" 
                                                            value={formData.title} 
                                                            onChange={handleChange} 
                                                            required 
                                                        />
                                                        <h6>Title</h6>
                                                    </div>
                                                </div>

                                                <div className="row-combind">
                                                    <div className='email-wrap field-combind'>
                                                        <input 
                                                            type="email" 
                                                            name="email" 
                                                            placeholder="Type here" 
                                                            value={formData.email} 
                                                            onChange={handleChange} 
                                                            required 
                                                        />
                                                        <h6>Email</h6>
                                                    </div>
                                                    <div className='phone-wrap field-combind'>
                                                        <input 
                                                            type="text" 
                                                            name="phone" 
                                                            placeholder="Type here" 
                                                            value={formData.phone} 
                                                            onChange={handleChange} 
                                                            required 
                                                        />
                                                        <h6>Phone</h6>
                                                    </div>
                                                </div>

                                                <div className='register-button'>
                                                    <button type="button" className='next-btn button' onClick={nextStep}>Next</button>
                                                </div>
                                            </div>
                                        )}

                                        {step === 2 && (
                                            <div className="2nd-step-fields">
                                                <img src={secondProgressbar} alt="progressbar2" className='progressbar' />
                                                <h6>Finish Creating Your Account</h6>
                                                <p className='sub-para'>Set up your login details to access your dashboard.</p>
                                                <label>Username (Primary Email) <span>*</span></label>
                                        <input type="text" name="username" placeholder="Choose a username" value={formData.username} onChange={handleChange} required />
                                                <label>Password <span>*</span></label>
                                                <div className="password-field">
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        name="password"
                                                        placeholder="Create a password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                                                        {showPassword ? <FaEyeSlash size={24} color="#7D8287"/> : <FaEye size={24} color="#7D8287" />}
                                                    </span>
                                                </div>

                                                <label>Confirm Password <span>*</span></label>
                                                <div className="password-field">
                                                    <input
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        name="confirmPassword"
                                                        placeholder="Confirm your password"
                                                        value={formData.confirmPassword}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <span onClick={toggleConfirmPasswordVisibility} className="password-toggle-icon">
                                                        {showConfirmPassword ? <FaEyeSlash size={24} color="#7D8287"/> : <FaEye size={24} color="#7D8287" />}
                                                    </span>
                                                </div>

                                                <div className='register-button'>
                                                    <button type="button" className='back-btn button' onClick={prevStep}>Back</button>
                                                    <button type="submit" className='next-btn register-btn button'>Finish</button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
