import React from 'react';
import './CompanyVideoIntro.css';

function CompanyVideoIntro() {
  return (
    <div className='company-video-intro'>
      <h4>Company Video Overview</h4>
      <iframe
        width="100%"
        height="auto"
        src="https://www.youtube.com/embed/ZzlA2a_xycM" // YouTube video ID se banaye gaye link ka istemal karein
        title="Company Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default CompanyVideoIntro;
