import React, { useState, useEffect } from 'react';
import Header from '../../components/company/dashboard/Header/Header';
import { getCandidates } from '../../services/candidateService';
import CandidateGrid from '../../components/company/browseCandidates/CandidateGrid';
import CandidateList from '../../components/company/browseCandidates/CandidateList';
import ToggleViewButton from '../../components/company/browseCandidates/ToggleViewButton';
import Sidebar from '../../components/company/dashboard/Sidebar/Sidebar';
import matchIcon from '../../assets/imgs/matchIcon.png';
import './MatchCandidates.css';

const MatchCandidates = () => {
  const [candidates, setCandidates] = useState([]);
  const [view, setView] = useState('grid');
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const handleToggleSidebar = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const data = await getCandidates();
        console.log('Fetched candidates:', data); // Debugging line
        setCandidates(data);
      } catch (error) {
        console.error('Failed to fetch candidates:', error); // Error logging
      }
    };
    fetchCandidates();
  }, []);

  return (
    <div className={`main-section matchsection `}>
      <Header />
      <div className='row box'>
        <div className={`col-lg-2 col-md-2 col-sm-4 col-4 ${isSidebarCollapsed ? 'sidebarCollapsed' : ''}`}>
          <Sidebar onToggleSidebar={handleToggleSidebar} />
        </div>
        <div className={`col-lg-10 col-md-10 col-sm-8 col-8   contentbar ${isSidebarCollapsed ? 'contentExpanded' : ''}`}>
          <div className='container-fluid'>
            <h2 className='main-heading'><img src={matchIcon} alt='favicon' />Your Matched Candidates</h2>
            <ToggleViewButton view={view} setView={setView} />
            {candidates.length === 0 ? (
              <p>No candidates found</p>
            ) : (
              view === 'grid' ? (
                <CandidateGrid candidates={candidates} />
              ) : (
                <CandidateList candidates={candidates} />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchCandidates;
