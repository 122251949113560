// src/components/Login.js
import React, { useState, useContext } from 'react'; // Import useContext
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { AuthContext } from '../../../context/AuthContext'; // Import AuthContext
import './Login.css'; // CSS file for styling
import Header from '../dashboard/Header/Header';
import loginLogo from '../../../assets/imgs/7eagle-logo.png';
import bgImg from '../../../assets/imgs/bg.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 
import { toast, ToastContainer } from 'react-toastify';
import userImg from '../../../assets/imgs/userblank.png';
import partnerIcon from '../../../assets/imgs/partnerIcon.png';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const { login } = useContext(AuthContext); // Get login function from AuthContext
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    // const [message, setMessage] = useState('');
    const [keepSignedIn, setKeepSignedIn] = useState(false); // State for Keep Me Signed In
    const navigate = useNavigate(); // useNavigate hook for navigation
    const [isCompany, setIsCompany] = useState(true);
    const [isUser, setIsUser] = useState(false); // State for user checkbox
    const [showForm, setShowForm] = useState(false); // State to control form visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleUserTypeChange = (type) => {
        if (type === 'company') {
            setIsCompany(true);
            setIsUser(false);
        } else {
            setIsUser(true);
            setIsCompany(false);
        }
    };

    const handleShowForm = () => {
        setShowForm(true);
      
    };

    const handleJoinUser = () => {
        navigate('/talent/login'); // Redirect to user registration page
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:5000/api/users/login', {
                username,
                password,
            });
            
         
    
            // Store token based on keepSignedIn option
            const token = response.data.token;
            if (keepSignedIn) {
                localStorage.setItem('token', token); // Store token in localStorage
            } else {
                sessionStorage.setItem('token', token); // Store token in sessionStorage
            }
    
            // Call login function from AuthContext and pass the token
            login(token); // Pass the token to the AuthContext
            toast.error('Thank you for logging in!');
            // Redirect the user after successful login
      
         
    
        } catch (error) {
            toast.error(error.response ? error.response.data.message : 'Login Failed');
        }
    };

    return (
      <div className='main-login'>
      <Header/>
      <ToastContainer/>
      <div className='row' style={{alignItems:'center'}}>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='login-banner'>
                                        <img src={bgImg} alt='company-banner' />
                                    </div>
                 </div>
                 <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div>
                    <div className="login-container">

                    <>
                            {!showForm && (
                                <>
                                          <img src={loginLogo} alt='company-logo' className='company-logo'/>
                            <h2 className="user-type-heading-login">
                               
                            Log In To 7 Eagle Group
                            </h2>
                                </>
                               )}
                            </>
                            {/* Show user type selection */}
                            {!showForm && (
                           <div className="user-type-selection">
                           <div className={`selection-box ${isCompany ? 'active-company' : ''}`}>
                               <label>
                                   <img src={partnerIcon} alt='partnericon' />
                                   <input 
                                       type="radio" 
                                       checked={isCompany} 
                                       onChange={() => handleUserTypeChange('company')} 
                                   />
                               </label>
                               <p>I’m a partner company looking for top military talent</p>
                           </div>
                       
                           <div className={`selection-box ${isUser ? 'active-user' : ''}`}>
                               <label>
                                   <img src={userImg} alt='userImg' />
                                   <input 
                                       type="radio" 
                                       checked={isUser} 
                                       onChange={() => handleUserTypeChange('user')} 
                                   />
                               </label>
                               <p>I’m a military talent looking for great companies</p>
                           </div>
                       </div>
                       
                            )}

                            {/* Show button for company registration */}
                            {isCompany && !showForm && (
                                <div className='button-container'>
                                    <button className='company-register-btn' onClick={handleShowForm}>
                                       Continue
                                    </button>

                                    <p className="signup-link">
                        Don’t have a 7 Eagle Account? 
                        </p>

                            <div className='signup-btn'>
                            <Link to="/company/register">Sign Up</Link>
                            </div>
                 
                                </div>
                            )}

                            {isUser && !showForm && (
                                <div className='button-container'>
                                    <button className='user-register-btn' onClick={handleJoinUser}>
                                    Continue
                                    </button>

                                    <p className="signup-link">
                        Don’t have a 7 Eagle Account? 
                        </p>

                            <div className='signup-btn'>
                            <Link to="/company/register">Sign Up</Link>
                            </div>
                 
                                </div>
                            )}
  {showForm && isCompany && (

                 <>
                    <img src={loginLogo} alt='company-logo' className='company-logo'/>
                              <h2>Log In As A Partner Company</h2>

                    <form onSubmit={handleLogin}>
                        
                        <input
                            type="text"
                            placeholder="Username / Email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <div className="password-field">
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />

        <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                                                                {showPassword ? <FaEyeSlash size={24} color="#7D8287" /> : <FaEye size={24} color="#7D8287" />}
        </span>
                        </div>
                    
                        <div className='keepSign' > 
                            <div className='keep-sub'>
                            <input
                            className='checkbox'
                                type="checkbox"
                                checked={keepSignedIn}
                                onChange={(e) => setKeepSignedIn(e.target.checked)}
                            />
                            <label>Keep Me Signed In</label>
                            </div>

                            <div className='keep-sub'>
                                <Link>Forgot password?</Link>
                            </div>
                        </div>
                        <button type="submit">Login</button>
                    </form>
          
                        <p className="signup-link">
                        Don’t have a 7 Eagle Account? 
                        </p>

                            <div className='signup-btn'>
                            <Link to="/company/register">Sign Up</Link>
                            </div>
                 
                 </>
                             )}
                     </div>
                    </div>
                 </div>
      </div>
      
      </div>
    );
};

export default Login;
