import React from 'react';
import userImg from '../../../../assets/imgs/user.png';


import './ScheduledInterviewUpcominglist.css';

function ScheduledInterviewUpcomingList() {
  // Hardcoded list of interviews for now
  const interviews = [
    {
      date: 'Today',
      time: '10:00 - 10:30AM EST',
      candidateId: 'ID32345',
      userImg: null, // This will fall back to the default image
    },
    {
      date: 'Tomorrow',
      time: '11:00 - 11:30AM EST',
      candidateId: 'ID32346',
      userImg: null,
    },
    // Add more interview objects as needed
  ];

  return (
    <div className='schedule-box'>
      {interviews.map((interview, index) => (
        <div className='scheduledList' key={index}>
          <div className='scheduled-date'>
            <h6 className='activeDate'>{interview.date}</h6>
            <p>{interview.time}</p>
          </div>
          <div className='candidate-info'>
            <div className='sub-box'>
              <img src={interview.userImg || userImg} alt='userImg' />
              <h6>{interview.candidateId}</h6>
            </div>
          </div>
          <div className='zoom-btn'>
            <button>Join Zoom</button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ScheduledInterviewUpcomingList;
