import React, { useState, useEffect } from 'react';
import { FaRegHeart } from 'react-icons/fa';
import rightArrow from '../../../assets/imgs/right-arrow.png';
import CandidateSearch from './CandidateSearch';
import './CandidateList.css';
import bottomtArrow from '../../../assets/imgs/bottomIcon.png';


const CandidateList = ({ candidates }) => {
  const [expandedId, setExpandedId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredCandidates, setFilteredCandidates] = useState(candidates);
  const [searchTerm, setSearchTerm] = useState('');
  const candidatesPerPage = 1; // Number of candidates to display per page

  useEffect(() => {
    setFilteredCandidates(candidates);
  }, [candidates]);

  // Calculate the indexes for slicing the candidates array
  const indexOfLastCandidate = currentPage * candidatesPerPage;
  const indexOfFirstCandidate = indexOfLastCandidate - candidatesPerPage;
  const currentCandidates = filteredCandidates.slice(indexOfFirstCandidate, indexOfLastCandidate);

  const totalPages = Math.ceil(filteredCandidates.length / candidatesPerPage);

  // Function to handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to handle Next button click
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to handle Previous button click
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const toggleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  // Search functionality
  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    const searchedCandidates = candidates.filter(candidate => {
      const id = candidate.recruiterFlowId ? candidate.recruiterFlowId.toLowerCase() : '';
      const branch = candidate.branch ? candidate.branch.toLowerCase() : '';
      return id.includes(searchTerm.toLowerCase()) || branch.includes(searchTerm.toLowerCase());
    });
    setFilteredCandidates(searchedCandidates);
    setCurrentPage(1); // Reset to first page after search
  };

  // Filter functionality
  const handleFilter = (filters) => {
    let filteredList = candidates;

    if (filters.branch) {
      filteredList = filteredList.filter(candidate =>
        candidate.branch && candidate.branch.toLowerCase().includes(filters.branch.toLowerCase())
      );
    }
    if (filters.clearance) {
      filteredList = filteredList.filter(candidate =>
        candidate.clearance && candidate.clearance.toLowerCase().includes(filters.clearance.toLowerCase())
      );
    }
    if (filters.rank) {
      filteredList = filteredList.filter(candidate =>
        candidate.rank && candidate.rank.toLowerCase().includes(filters.rank.toLowerCase())
      );
    }

    setFilteredCandidates(filteredList);
    setCurrentPage(1);
  };

  // Sort functionality
  const handleSort = (sortType) => {
    let sortedList = [...filteredCandidates];
    sortedList.sort((a, b) => {
      const aValue = a[sortType] ? a[sortType].toLowerCase() : '';
      const bValue = b[sortType] ? b[sortType].toLowerCase() : '';
      return aValue.localeCompare(bValue);
    });
    setFilteredCandidates(sortedList);
  };

  // Create an array of page numbers for pagination
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // Function to create a limited set of page numbers to display
  const getDisplayedPageNumbers = () => {
    const displayedPages = [];
    const maxDisplayed = 5; // Maximum number of pages to display at once
    const start = Math.max(1, currentPage - Math.floor(maxDisplayed / 2));
    const end = Math.min(totalPages, start + maxDisplayed - 1);

    for (let i = start; i <= end; i++) {
      displayedPages.push(i);
    }

    // Add ellipsis if needed
    if (start > 1) displayedPages.unshift('...');
    if (end < totalPages) displayedPages.push('...');

    return displayedPages;
  };

  return (
    <div className='list-grid'>
      <CandidateSearch onSearch={handleSearch} onFilter={handleFilter} onSort={handleSort} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {currentCandidates.map(candidate => (
          <div key={candidate._id} className='listbox'>
            <div className='candidate-header-info' onClick={() => toggleExpand(candidate._id)}>
              <div className='left'>
                <iframe
                  src='https://www.youtube.com/embed/6_LmbJsxlb0'
                  width="100"
                  height="100"
                  title="Candidate Video"
                  allowFullScreen
                ></iframe>
                <div className='sub-header'>
                  <h3 className='candidate-id'>{candidate.recruiterFlowId} <span>Veteran</span></h3>
                  <h6 className='candidate-branch'>{candidate.branch}</h6>
                </div>
              </div>
              <div className='list-grid-btns'>
                <div className='right-fav-btn'>
                  <FaRegHeart className="heart-icon" style={{ color: 'gray', fontSize: '20px' }} />
                </div>
                <div className='profile-btns list-btns'>
                  <button className='view-more-btn'>More Options <img src={bottomtArrow} alt='bottomArrow' /></button>
                  <button className='view-profile-btn'>View Profile <img src={rightArrow} alt='right' /></button>
                </div>
              </div>
            </div>

            {expandedId === candidate._id && (
              <div className='candidate-detail'>
                <div className='fields'>
                  <div className='field'><p className='field-name'>Preferred Job:</p> <p className='field-value'>Cyber Security Analyst</p></div>
                  <div className='field'><p className='field-name'>Clearance:</p> <p className='field-value'>Top Secret with no Poly</p></div>
                  <div className='field'><p className='field-name'>Military Rank:</p> <p className='field-value'>E4</p></div>
                  <div className='field'><p className='field-name'>Certifications:</p> <p className='field-value'>CompTIA Security+, Network+, A+, CASP + (Anticipate Completion Date – August 2024)</p></div>
                  <div className='field'><p className='field-name'>Education:</p> <p className='field-value'>Information Technology Southern New Hampshire University (2025)</p></div>
                  <div className='field'><p className='field-name'>Available:</p> <p className='field-value'>August 25, 2025</p></div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Pagination with limited numbers */}
      <ul className="pagination">
        <li>
          <button className="page-link-previous" onClick={handlePrevious} disabled={currentPage === 1}>&lt;</button>
        </li>

        {/* Display limited page numbers */}
        {getDisplayedPageNumbers().map((number, index) => (
          <li key={index}>
            {number === '...' ? (
              <span className="ellipsis">...</span>
            ) : (
              <button
                className={`page-button ${currentPage === number ? 'active' : ''}`}
                onClick={() => paginate(number)}
              >
                {number}
              </button>
            )}
          </li>
        ))}

        <li>
          <button className="page-link-next" onClick={handleNext} disabled={currentPage === totalPages}>&gt;</button>
        </li>
      </ul>
    </div>
  );
};

export default CandidateList;
