import React from 'react'
import './ProfileAbout.css';
function ProfileAbout() {
  return (
    <div className='about-section'>
        <h4>About Cybersurance</h4>
        <p>
        CyberSurance is a leader in providing cyber-security consulting services with a focus on providing resources and expertise to help business organizations navigate the complexities of developing and operating a security management program. <br/><br/>
At CyberSurance, our depth of knowledge and expertise enables us to take a broader approach to assessing risks and developing comprehensive solutions. Our cybersecurity consultants have served as Chief Information Security Officers (CISO) – with extensive experience and industry certifications in ethical hacking, risk management, security management, security auditing, and business consulting. Our testing and auditing services employ proven methodologies along with a combination of commercial, open-source, manual, and home-grown testing tools and techniques. <br/><br/>
CyberSurance has served as a strategic partner to many top companies in the financial, healthcare, defense, transportation, communications, entertainment, and e-commerce industries.
We understand that security should enable and enhance the business process. Our goal is to provide services that proactively address your risk-based security needs, regulatory requirements, and compliance and governance issues – all in coordination with your specific business objectives.
        </p>
    </div>
  )
}

export default ProfileAbout