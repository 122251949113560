import React, { useState } from 'react';
import './AccountProgress.css';

function AccountProgress() {
  const [checkboxes, setCheckboxes] = useState([
    { id: 'checkbox1', label: 'Sign our partnership agreement to earn a Verified Badge', checked: false },
    { id: 'checkbox2', label: 'Upload company photos', checked: false },
    { id: 'checkbox3', label: 'Upload a video overview about your company', checked: false },
    { id: 'checkbox4', label: 'Ask for reviews for your company', checked: false },
    { id: 'checkbox5', label: 'Upload your custom cover photo', checked: false },
    { id: 'checkbox6', label: 'Favorite a candidate', checked: false },
  ]);

  // Checkbox ko toggle karne ka function
  const handleCheckboxChange = (id) => {
    setCheckboxes(checkboxes.map(checkbox => 
      checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
    ));
  };

  // Progress calculate karne ka function
  const completed = checkboxes.filter(checkbox => checkbox.checked).length;
  const total = checkboxes.length;
  const progress = (completed / total) * 100;

  // Circle ka radius aur circumference
  const radius = 50; // Circle ka radius barhaya gaya
  const circumference = 2 * Math.PI * radius;

  return (
    <div className='accountProgress_sec'>
      <h4>Account Progress</h4>
      <div className='row row-circle'>
        <div className='col-lg-2 col-md-2 col-sm-3 col-3'>
          <div className='circle-box'> {/* Circle box size set kiya */}
            <svg width="120" height="120"> {/* SVG size set kiya */}
              <circle cx="60" cy="60" r={radius} stroke="#C6FFB2" strokeWidth="10" fill="none" />
              <circle
                cx="60"
                cy="60"
                r={radius}
                stroke="#1E6B02" // Updated stroke color
                strokeWidth="10"
                fill="none"
                strokeDasharray={`${(progress / 100) * circumference} ${circumference}`}
                strokeDashoffset={circumference - (progress / 100) * circumference}
                transform="rotate(-90 60 60)" // Rotate around center
              />
              <text x="60" y="70" textAnchor="middle" fontSize="20" fill="#1E6B02">{Math.round(progress)}%</text>
            </svg>
          </div>
        </div>
        <div className='col-lg-10 col-md-10 col-sm-9 col-9'>
          <div className='check-boxes'>
            {checkboxes.map(checkbox => (
              <div className='checkbox' key={checkbox.id}>
                <input
                  type="checkbox"
                  id={checkbox.id}
                  checked={checkbox.checked}
                  onChange={() => handleCheckboxChange(checkbox.id)}
                />
                <label htmlFor={checkbox.id}>
                  {checkbox.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountProgress;
